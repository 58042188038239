import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { AdditionMenu } from '../../style/style'
import { MenuOutlined } from '@ant-design/icons'

const HeaderMenuBox = ({ data }: any) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen((prev: boolean) => !prev)
  }

  return (
    <AdditionMenu>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <span onClick={handleClick} className="menu-mobile">
          <MenuOutlined style={{ fontSize: 30, color: '#fff' }} />
        </span>
      </div>
      <ul className={open ? 'active' : ''}>
        {data.map(({ id, to, title }: any) => (
          <li key={id}>
            <NavLink to={to} onClick={() => setOpen(false)}>
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </AdditionMenu>
  )
}

export default HeaderMenuBox
