import React from 'react'
import MenuLogoWoman from '../../../static/media/logoMenu3.png'

export const listHeaderMenu = (t: any) => [
  {
    id: 1,
    title: t('m13'),
    to: '/hunting?type=7',
  },
  {
    id: 2,
    title: t('m14'),
    to: '/fishing?type=8',
  },
  {
    id: 3,
    title: t('m15'),
    to: '/tourism?type=9',
  },
  {
    id: 4,
    title: t('m8'),
    to: '/cynology?type=10',
  },
  {
    id: 5,
    title: t('m7'),
    to: '/trophy-book/hunter-book',
  },
  {
    id: 6,
    title: <img height="80" alt="example" src={MenuLogoWoman} />,
    to: '/womans-club?type=17',
  },
]
